export default function createListView(channelId, rpage, component) {
  return {
    // name: `${type}-stories-view`,

    asyncData({
      store
    }) {
      return store.dispatch('FETCH_VIDEO_LIST', {
        channelId,
        platform: "IOS_PPS",
        from: 0,
        size: 30,
        source:"new_request"
      })
    },

    render(h) {
      return h(component, {
        props: {
          // channelId,
          rpage
        }
      })
    },
    beforeDestroy () {
      console.log("-------beforeDestroy-----")
    },
  }
}